<template lang="pug">
  Section.cy-closing-section(
    required
    contains-dropdown
    :class="{ 'invalid-section': isInvalidSection }"
    :title="$t('plan_settings.basic_settings.sections.closing.section_name')"
  )
    .row.no-gutters.w-100
      .col
        AppDropdown.cy-closing(
          close-on-select
          order-direction="keep"
          :class="{ invalid: closingTypeError }"
          :items="items"
          :value="selectedClosingOption"
          @select="handleSelect($event, 'closing_type')"
        )
      .col
        .date-time-inputs(
          v-if="businessHoursOptions.includes(selectedClosingOptionId)"
        )
          AppNumberInput(
            :invalid="hoursBeforeError"
            v-model="object.hours_before.$model"
          )
          span.m-x-8.text {{ $t("plan_settings.basic_settings.hours_before") }}
          span.text-muted {{ $t("plan_settings.basic_settings.based_on_business_hours") }}
        .date-time-inputs(
          v-if="deadlineHolidaysOptions.includes(selectedClosingOptionId)"
        )
          AppNumberInput(
            :invalid="endDayError"
            v-model="object.end_day.$model"
          )
          span.m-x-8.text {{ endDayLabel }}
          AppNumberInput(
            :invalid="oClockError"
            v-model="object.o_clock.$model"
            :max="24"
          )
          span.m-x-8.text {{ $t("plan_settings.basic_settings.o_clock") }}
</template>
<script>
  // mixins
  import salesDatesMethods from "@/pages/PriceManagement/PlanSettings/BasicSettings/Settings/Rakuten/shared/salesDatesMethods"

  // misc
  import { find } from "lodash-es"
  import { getClosingOptions } from "@/config/translations_helper"

  export default {
    mixins: [salesDatesMethods],

    computed: {
      items() {
        return getClosingOptions(this.$i18n)
      },

      selectedClosingOption() {
        return find(this.items, { id: this.object.closing_type.$model })
      },

      selectedClosingOptionId() {
        return this.selectedClosingOption?.id
      },

      closingTypeError() {
        return this.object.closing_type.$error
      },

      isInvalidSection() {
        return this.closingTypeError || this.hoursBeforeError || this.endDayError || this.oClockError
      },

      endDayLabel() {
        return this.$t(`plan_settings.basic_settings.${this.object.end_day.$model > 1 ? "days_before" : "day_before"}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .row
    +styled-inputs
    +date-time-inputs
</style>
